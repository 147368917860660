import { CampsiteFilters, SortByEnum, SortDirEnum } from "models";

export const defaultFilterState: CampsiteFilters = {
  sort_by: SortByEnum.NAME,
  sort_dir: SortDirEnum.ASC,
  code__ct: null,
  name__ct: null,
  state: null,
  country: null,
  campsite_type: null,
  month_open__lt: null,
  month_close__gt: null,
  elevation_ft__gt: null,
  elevation_ft__lt: null,
  num_campsites__gt: null,
  num_campsites__lt: null,
  nearest_town_distance__lt: null,
  has_rv_hookup: null,
  has_water_hookup: null,
  has_electric_hookup: null,
  has_sewer_hookup: null,
  has_sanitary_dump: null,
  max_rv_length__gt: null,
  has_toilets: null,
  toilet_type: null,
  has_drinking_water: null,
  has_showers: null,
  accepts_reservations: null,
  accepts_pets: null,
  low_no_fee: null,
};
